import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Sidebar from "./sidebar";
import { useApiUrl } from "../api/Api";
import { Bar, Pie } from "react-chartjs-2";
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
Chart.register(CategoryScale, LinearScale, BarElement, ArcElement, Tooltip, Legend);

export default function Events() {
  const apiUrl = useApiUrl();
  const { id } = useParams();
  const navigate = useNavigate();
  const [eventData, setEventData] = useState(null);
  const [userData, setUserData] = useState([]);
  const [summaryData, setSummaryData] = useState({
    totalPerProduct: {},
    topContributors: [],
    totalWaste: 0,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const [eventResponse, userResponse] = await Promise.all([
          axios.get(`${apiUrl}/events/display/${id}`),
          axios.get(`${apiUrl}/events/getusersinevent/${id}`),
        ]);

        setEventData(eventResponse.data);
        setUserData(userResponse.data);
        processSummaryData(userResponse.data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    if (id) {
      fetchEventData();
    }
  }, [id, apiUrl]);

  const processSummaryData = (data) => {
    const totalPerProduct = {};
    const userContributions = {};

    data.forEach((item) => {
      const quantity = parseInt(item.quantity, 10);

      if (!isNaN(quantity)) {
        if (totalPerProduct[item.product_name]) {
          totalPerProduct[item.product_name] += quantity;
        } else {
          totalPerProduct[item.product_name] = quantity;
        }
        if (userContributions[item.username]) {
          userContributions[item.username] += quantity;
        } else {
          userContributions[item.username] = quantity;
        }
      }
    });

    const topContributors = Object.entries(userContributions)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 5)
      .map(([username, quantity]) => ({ username, quantity }));

    const totalWaste = Object.values(totalPerProduct).reduce(
      (acc, qty) => acc + qty,
      0
    );

    setSummaryData({
      totalPerProduct,
      topContributors,
      totalWaste,
    });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const productNames = Object.keys(summaryData.totalPerProduct);
  const productQuantities = Object.values(summaryData.totalPerProduct);

  const productChartData = {
    labels: productNames,
    datasets: [
      {
        label: "Total Quantity",
        data: productQuantities,
        backgroundColor: "rgba(54, 162, 235, 0.6)",
      },
    ],
  };

  const contributorNames = summaryData.topContributors.map((item) => item.username);
  const contributorQuantities = summaryData.topContributors.map((item) => item.quantity);

  const contributorsChartData = {
    labels: contributorNames,
    datasets: [
      {
        label: "Contributions",
        data: contributorQuantities,
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
        ],
      },
    ],
  };

  const totalWasteChartData = {
    labels: ["Total Waste"],
    datasets: [
      {
        label: "Quantity",
        data: [summaryData.totalWaste],
        backgroundColor: "rgba(255, 99, 132, 0.6)",
      },
    ],
  };

  const chartOptions = {
    maintainAspectRatio: false, 
    responsive: true,
  };

  return (
    <div className="flex h-screen">
      <Sidebar />
      <div className="bg-white p-4 w-full ml-[20%] flex flex-col">
        <div className="bg-white p-4 w-full rounded-lg mt-14">
          <button
            onClick={() => navigate(-1)}
            className="mb-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Back
          </button>
          <h1 className="text-2xl font-bold text-gray-800 mb-2">
            Event Name: {eventData[0]?.title}
          </h1>
          <h2 className="text-xl text-gray-700 mb-2">In Charge: {eventData[0]?.incharge}</h2>
          <h2 className="text-xl text-gray-700 mb-4">Date: {eventData[0]?.date}</h2>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <div className="bg-gray-100 p-4 rounded-lg">
              <h3 className="text-lg font-semibold mb-2">Total Quantity per Product</h3>
              <div style={{ height: "400px" }}>
                <Bar data={productChartData} options={chartOptions} />
              </div>
            </div>
            <div className="bg-gray-100 p-4 rounded-lg">
              <h3 className="text-lg font-semibold mb-2">Top Contributors</h3>
              <div style={{ height: "350px" }}>
                <Pie data={contributorsChartData} options={chartOptions} />
              </div>
            </div>

            <div className="bg-gray-100 p-4 rounded-lg col-span-1 md:col-span-2">
              <h3 className="text-lg font-semibold mb-2">Total Waste Collected</h3>
              <div style={{ height: "300px" }}>
                <Bar
                  data={totalWasteChartData}
                  options={{
                    ...chartOptions,
                    indexAxis: "y",
                    scales: {
                      x: {
                        beginAtZero: true,
                      },
                    },
                  }}
                />
              </div>
            </div>
          </div>

          <table className="min-w-full bg-white border-collapse">
            <thead>
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Username
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Product
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Quantity
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {userData.map((contribution, index) => (
                <tr key={index}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">
                    {contribution.username}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">
                    {contribution.product_name}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">
                    {parseInt(contribution.quantity, 10)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
